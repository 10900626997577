.active {
  color: blue;
  text-decoration-line: underline;
}
.contain {
  display: 'flex';
  justify-content: 'space-around';
  height: '100vh';
  background-color: '#929292' ;
}

*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
#button {
  width: 163px;
  height: 215px;
  background-color: white;
  border-radius: 20px !important;
  text-align: center;
  color: black;
}
#circle {
  border-radius: 50%;
    width: 25px;
    height: 25px;
}
#fan {
  border-radius: 50%;
  font-size: 15px;
}
#tab {
  border-width: 1px;
   border-top-left-radius: 20px;
    border-top-right-radius: 30px;
  z-index: 99;
   width: 30vw;
   /* height: 30%; */
    background-color: white;
    position: fixed;
     bottom: 0px;
     right: 0px;
  display: flex;
   justify-content: flex-start;
   align-items:center;
   flex-direction:column;
}
@media (max-width: 767px) {
  #tab {
    border-width: 1px;
    border-top-left-radius: 20px;
     border-top-right-radius: 30px;
   z-index: 99;
    width: 100vw;
    /* height: 40%; */
     background-color: white;
     position: fixed;
      bottom: 0px;
   display: flex;
    justify-content: flex-start;
    align-items:center;
    flex-direction:column;
  }
}
#online {
  color: #57BF40;
  margin-top: -15px;
  position: relative;
  float: right;
}
#offline {
  color: red;
  margin-top: -15px;
float: right;
}
#border {
  border-radius: 10px !important;
}